<template>
  <div v-if="editedCustomer">
    <validation-observer tag="form"
                         ref="formStatus"
                         @submit.prevent="submit"
                         v-slot="{invalid, errors}">
      <div class="form-group row">
        <div class="col-12 col-md-6"
             v-if="activeField('firstName')">
          <label for="firstNameInput">Vorname</label>
          <validation-provider name="Vorname" rules="required" v-slot="{errors}">
            <input
                v-model="editedCustomer.firstName"
                name="fname"
                id="firstNameInput"
                class="form-control"
                type="text"
                autocomplete="given-name"
                autofocus>
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-12 mt-2 col-md-6 mt-md-0"
             v-if="activeField('lastName')">
          <label for="lastNameInput">Nachname</label>
          <validation-provider name="Nachname" rules="required" v-slot="{errors}">
            <input
                v-model="editedCustomer.lastName"
                name="lname"
                id="lastNameInput"
                class="form-control"
                type="text"
                autocomplete="family-name">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row"
           v-if="activeField('company')">
        <div class="col-12">
          <label for="companyInput">Firma</label>
          <input
              v-model="editedCustomer.company"
              name="comapny"
              id="companyInput"
              class="form-control"
              type="text"
              autocomplete="company">
        </div>
      </div>
      <div class="form-group row"
           v-if="activeField('address')">
        <div class="col-12">
          <label for="streetInput">Straße und Hausnummer</label>
          <validation-provider name="Straße" rules="required" v-slot="{errors}">
            <input
                v-model="editedCustomer.streetAndNumber"
                name="address"
                id="streetInput"
                class="form-control"
                type="text"
                autocomplete="street-address">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row"
           v-if="activeField('address')">
        <div class="col-4">
          <label for="zipInput">PLZ</label>
          <validation-provider name="Postleitzahl" rules="zip|required" v-slot="{errors}">
            <input
                v-model="editedCustomer.zip"
                name="zip"
                id="zipInput"
                class="form-control"
                type="text"
                minlength="5"
                maxlength="5"
                autocomplete="postal-code">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-8">
          <label for="cityInput">Stadt</label>
          <validation-provider name="Stadt" rules="required" v-slot="{errors}">
            <input
                v-model="editedCustomer.city"
                name="city"
                id="cityInput"
                class="form-control"
                type="text"
                autocomplete="address-level2">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group row"
           v-if="activeField('email')">
        <div class="col-12 col-md-6">
          <label for="emailInput">Email</label>
          <validation-provider name="Email" rules="email|required" v-slot="{errors}">
            <input
                v-model="editedCustomer.email"
                name="email"
                id="emailInput"
                class="form-control"
                type="email"
                autocomplete="email">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="col-12 mt-2 col-md-6 mt-md-0"
             v-if="activeField('phone')">
          <label for="phoneInput">Telefonnummer für Rückfragen</label>
          <validation-provider name="Telefonnummer" rules="phone|required" v-slot="{errors}">
            <input
                v-model="editedCustomer.phone"
                name="phone"
                id="phoneInput"
                class="form-control"
                type="tel"
                autocomplete="tel">
            <span class="form-error">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <validation-provider tag="div"
                           name="Datenschutzbestimmungen"
                           class="row flex-nowrap"
                           rules="accepted"
                           v-slot="{errors}"
                           v-if="activeField('dp')">
        <div class="flex-grow-1 text-right"
             :class="{'form-error': errors && errors.length > 0}">
          Ich stimme den&nbsp;
          <router-link :to="{name: 'dataPrivacy'}">
            Datenschutzbestimmungen
          </router-link>
          &nbsp;zu
        </div>
        <div class="flex-grow-0 mx-3 my-auto">
          <input id="dpAccept"
                 class="form-check form-check-inline my-auto"
                 type="checkbox"
                 v-model="dpAccepted"/>
        </div>
      </validation-provider>
      <validation-provider tag="div"
                           name="AGBs"
                           class="row flex-nowrap"
                           rules="accepted"
                           v-slot="{errors}"
                           v-if="activeField('agb')">
        <div class="flex-grow-1 text-right"
             :class="{'form-error': errors && errors.length > 0}">
          Ich stimme den&nbsp;
          <AGBLink>
            Allgemeinen Geschäftsbedingungen
          </AGBLink>
          &nbsp;zu
        </div>
        <div class="flex-grow-0 mx-3 my-auto">
          <input id="agbAccept"
                 class="form-check form-check-inline my-auto"
                 type="checkbox"
                 v-model="agbAccepted"/>
        </div>
      </validation-provider>
      <div class="row mt-4">
        <div class="col text-right">
          <cld-image public-id="gdpr" height="40" width="40" alt="gdpr"/>
          <cld-image public-id="dsgvo" height="40" width="40" alt="dsgvo"/>
        </div>
        <div class="col text-right">
          <button type="submit"
                  class="btn btn-secondary ml-2 text-nowrap">
            {{ submitLabel }}
          </button>
          <div class="small text-danger">
            <template v-for="errorArray in errors">
              <div v-for="error in errorArray" :key="'_err' + error">
                {{error}}
              </div>
            </template>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>

import AGBLink from "@/components/AGBLink";

export default {
  components: {AGBLink},
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    value: Object,
    submitLabel: {
      type: String,
      default: () => 'Fertig'
    }
  },
  data() {
    return {
      editedCustomer: null,
      dpAccepted: false,
      agbAccepted: false
    }
  },
  methods: {
    activeField(fieldName) {
      return this.fields.length === 0
          || this.fields.filter(f => f === fieldName).length > 0
    },
    init() {
      if (this.value)
        this.editedCustomer = JSON.parse(JSON.stringify(this.value))
    },
    submit() {
      this.$refs.formStatus.handleSubmit(() => {
        this.$emit('input', this.editedCustomer)
        this.$emit('save')
      })
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/custom";

.form-error {
  color: $danger;
}
</style>
